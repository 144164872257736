import { Component, ViewEncapsulation } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FormComponent, SetReadonlyFunc, SetValueFunc } from '@dft/shared/dialog/dialog.service';

@Component({
  templateUrl: 'plano-trabalho-aceite-form.component.html',
  styleUrls: ['plano-trabalho-aceite-form.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class PlanoTrabalhoAceiteFormComponent implements FormComponent {
  
  form: FormGroup;
  setFormValue: SetValueFunc;
  setFormReadOnly: SetReadonlyFunc;

}
