<mat-card class="mat-elevation-z8">
  <span class="mat-body-strong" style="color: darkslateblue">
    Para continuar utilizando o aplicativo é necessário ler e aceitar nossos termos de uso.
  </span>
</mat-card>

<br />

<mat-card class="mat-elevation-z8">
  <span class="mat-body-strong" style="color: darkslateblue">
    Termos de uso
  </span>
</mat-card>

<br />

<mat-card class="mat-elevation-z8">
  <span class="mat-body-strong" style="color: darkslateblue">
    Declaro que li e aceito os termos de uso.
  </span>
</mat-card>

<br />

<mat-card class="mat-elevation-z8">
  <span class="mat-body-strong" style="color: darkslateblue">
    Enquanto você não aceitar os termos de uso o aplicativo ficará inutilizável.
  </span>
</mat-card>

<br />
