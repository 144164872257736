import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { ModalidadeExecucao } from '../models/modalidade-execucao';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable({
  providedIn: 'root',
})
export class ModalidadeExecucaoService {

  constructor(private http: HttpClient) {}

  public listarModalidadesExecucoes(idOrgao: number): Observable<ModalidadeExecucao[]> {
    return this.http.get<ModalidadeExecucao[]>(`${environment.urlApi}/modalidades-execucoes/${idOrgao}`, httpOptions);
  }

  
}
