import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { Cargo } from '@dft/shared/models/cargo';
import { PessoaFuncional } from '@dft/shared/models/dado-funcional';
import { Gratificacao } from '@dft/shared/models/gratificacao';
import { Pessoa } from '@dft/shared/models/pessoa';
import { SituacaoFuncional } from '@dft/shared/models/situacao-funcional';
import { Vinculo } from '@dft/shared/models/vinculo';
import { CargoService } from '@dft/shared/services/cargo.service';
import { GratificacaoService } from '@dft/shared/services/gratificacao.service';
import { SituacaoFuncionalService } from '@dft/shared/services/situacao-funcional.service';
import { VinculoService } from '@dft/shared/services/vinculo.service';
import { Observable } from 'rxjs';
import { ModalidadeExecucao } from '@dft/shared/models/modalidade-execucao';
import { RegimeExecucao } from '@dft/shared/models/regime-execucao';
import { ModalidadeExecucaoService } from '@dft/shared/services/modalidade-execucao.service';
import { RegimeExecucaoService } from '@dft/shared/services/regime-execucao.service';

const MY_FORMATS = {
  parse: {
    dateInput: 'DD/MM/YYYY',
  },
  display: {
    dateInput: 'DD/MM/YYYY',
    monthYearLabel: 'MMM YYYY',
  },
};

@Component({
  templateUrl: './dado-funcional-form.component.html',
  styleUrls: ['./dado-funcional-form.component.scss'],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE],
    },
    { provide: MAT_DATE_LOCALE, useValue: 'pt-BR' },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  ],
})
export class DadoFuncionalFormComponent implements OnInit {
  form: FormGroup;
  formValue = null;
  readonly = false;

  pessoa: Pessoa;
  pessoaFuncional: PessoaFuncional;
  situacoesFuncionais$: Observable<SituacaoFuncional[]>;
  vinculos$: Observable<Vinculo[]>;
  modalidadesExecucoes$: Observable<ModalidadeExecucao[]>;
  regimesExecucoes$: Observable<RegimeExecucao[]>;
  gratificacoes$: Observable<Gratificacao[]>;
  cargos$: Observable<Cargo[]>;

  constructor(
    private _formBuilder: FormBuilder,
    private situacaoFuncionalService: SituacaoFuncionalService,
    private vinculoService: VinculoService,
    private modalidadeExecucaoService: ModalidadeExecucaoService,
    private regimeExecucaoService: RegimeExecucaoService,
    private gratificacaoService: GratificacaoService,
    private cargoService: CargoService,
  ) {
    this.form = this._formBuilder.group({
      id: this._formBuilder.control(''),
      pessoa: this._formBuilder.control(null),
      email: this._formBuilder.control(null, Validators.required),
      matricula: this._formBuilder.control(null, Validators.required),
      cargaHoraria: this._formBuilder.control(null, Validators.required),
      situacaoFuncional: this._formBuilder.control(null, Validators.required),
      vinculo: this._formBuilder.control(null, Validators.required),
      modalidadeExecucao: this._formBuilder.control(null, Validators.required),
      regimeExecucao: this._formBuilder.control(null, Validators.required),
      gratificacao: this._formBuilder.control(null),
      cargo: this._formBuilder.control(null),
      dataIngressoServPublico: this._formBuilder.control(null),
      dataIngressoOrgao: this._formBuilder.control(null),
      programaPGD: this._formBuilder.control(false),
    });
  }

  ngOnInit() {
    if (this.formValue) {
      this.pessoa = this.formValue.pessoa;
      this.pessoaFuncional = this.formValue.pessoaFuncional;

      const idOrgao = this.pessoa.orgao.id;
      this.vinculos$ = this.vinculoService
        .listarVinculos(idOrgao);
      this.situacoesFuncionais$ = this.situacaoFuncionalService
        .listarSituacoesFuncionais(idOrgao);
      this.gratificacoes$ = this.gratificacaoService
        .listarGratificacoes(idOrgao);
      this.cargos$ = this.cargoService.listarCargos(idOrgao);

      this.modalidadesExecucoes$ = this.modalidadeExecucaoService
        .listarModalidadesExecucoes(idOrgao);
      this.regimesExecucoes$ = this.regimeExecucaoService
        .listarRegimesExecucoes(idOrgao);

      if (this.formValue.pessoaFuncional) {
        this.form.setValue({
          pessoa: this.pessoa.nome,
          id: this.pessoa.id,
          email: this.pessoaFuncional.email,
          matricula: this.pessoaFuncional.matricula,
          cargaHoraria: this.pessoaFuncional.cargaHoraria,
          situacaoFuncional: this.pessoaFuncional.situacaoFuncional,
          vinculo: this.pessoaFuncional.vinculo,
          modalidadeExecucao: this.pessoaFuncional.modalidadeExecucao,
          regimeExecucao: this.pessoaFuncional.regimeExecucao,
          gratificacao: this.pessoaFuncional.gratificacao,
          cargo: this.pessoaFuncional.cargo,
          dataIngressoServPublico: this.pessoaFuncional.dataIngressoServPublico,
          dataIngressoOrgao: this.pessoaFuncional.dataIngressoOrgao,
          programaPGD: this.pessoaFuncional.programaPGD,
        });
      } else {
        this.form.setValue({
          pessoa: this.pessoa.nome,
          id: this.pessoa.id,
          email: null,
          matricula: null,
          cargaHoraria: null,
          situacaoFuncional: null,
          vinculo: null,
          gratificacao: null,
          cargo: null,
          dataIngressoServPublico: null,
          dataIngressoOrgao: null,
          programaPGD: false,
        });
      }
    }
  }

  public setFormValue(value: object): void {
    this.formValue = value;
  }

  public setFormReadOnly(readonly: boolean): void {
    this.readonly = readonly;
  }
}
