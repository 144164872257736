import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Sentinela } from '../models/sentinela';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
};

const API = `${environment.urlApi}/sentinelas`;

@Injectable({
  providedIn: 'root',
})
export class SentinelaService {

  sentinelas: Sentinela[];
  notificacoesNaoLidas: number = 0;

  constructor(private http: HttpClient) { }

  public listarSentinela(): Observable<Sentinela[]> {
    return this.http.get<Sentinela[]>(`${API}/`, httpOptions);
  }

  public listarMinhasNotificacoes(): Observable<Sentinela[]> {
    return this.http.get<Sentinela[]>(`${API}/minhas-notificacoes`, httpOptions);
  }

  public marcarMinhasNotificacoesComoLidas() {
    return this.http.post(`${API}/marcar-minhas-notificacoes-como-lidas`, httpOptions);
  }

}
