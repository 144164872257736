<header class="main-header">
  <a routerLink="/home" class="logo gft-banner">
    <img [src]="gftBanner" alt="GFT - Gestão da força de trabalho" />
  </a>

  <div *ngIf="isUsuarioAutenticado() | async">
    <ng-container *ngIf="{usuarioLogado: usuarioLogado$ | async, perfil: perfil$ | async} as dadosUsuario">
      <a
        tabindex="0"
        aria-label="Informações do Usuário"
        role="button"
        class="container-info-usuario"
        (click)="visualizarInfosUsuario(dadosUsuario.usuarioLogado, dadosUsuario.perfil)"
        (keypress)="visualizarInfosUsuario(dadosUsuario.usuarioLogado, dadosUsuario.perfil)"
        >
        <div *ngIf="notificacoesNaoLidas$ > 0" matTooltip="Você tem avisos da sentinela que estão não lidos." 
          matBadge="{{notificacoesNaoLidas$}}" matBadgeColor="warn" matBadgeOverlap="false" class="demo-section">
          <span class="nome">{{ dadosUsuario.usuarioLogado.nome }}</span>
        </div>
        <div *ngIf="!(notificacoesNaoLidas$ > 0)">
          <span class="nome">{{ dadosUsuario.usuarioLogado.nome }}</span>
        </div>
        <span class="info-perfil">{{ dadosUsuario.perfil.descricaoPerfilUnidade }}</span>
      </a>
    </ng-container>
  </div>
</header>
