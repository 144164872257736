import { Injectable, OnDestroy } from '@angular/core';
import { AuthService } from '@dft/security/auth/auth.service';
import { TipoPerfilEnum } from '@dft/shared/enums/tipo-perfil.enum';
import { ReplaySubject, Subscription } from 'rxjs';
import { Menu } from './menu';


@Injectable({ providedIn: 'root' })
export class OpcoesMenuService implements OnDestroy {
  private subscription: Subscription;
  private _menus = new ReplaySubject<Menu[]>(1);
  menus$ = this._menus.asObservable();


  constructor(private authService: AuthService) {
    this.subscription = this.authService.perfilSelecionado$.subscribe(perfil => {
      const descricaoPerfil = perfil ? perfil.descricaoPerfil : undefined;
      let menus: Menu[];

      if (descricaoPerfil) {
        menus = this.getMenus(descricaoPerfil);
      }
      this._menus.next(menus);
    });
  }

  ngOnDestroy(): void {
    if (!this.subscription.closed) {
      this.subscription.unsubscribe();
      this._menus.complete();
    }
  }

  getMenus(perfil: string): Menu[] {
    const menus: Menu[] = [];

    if (this.acessaMenuTipificacao(perfil)) {
      menus.push(this.getMenuTipificacao(perfil));
    }

    if (this.acessaMenuPessoa(perfil)) {
      menus.push(this.getMenuPessoa());
    }

    menus.push(this.getMenuDimensionamentos(perfil));

    menus.push(this.getMenuPlanoTrabalhos(perfil));

    if (this.acessaMenuRelatorios(perfil)) {
      menus.push(this.getMenuRelatorios(perfil));
    }

    if (this.acessaMenuConfiguracoes(perfil)) {
      menus.push(this.getMenuCentroCusto());
      menus.push(this.getMenuConfiguracoes());
    }
    return menus;
  }

  acessaMenuTipificacao(perfil: string): boolean {
    return perfil === TipoPerfilEnum.GESTOR_GERAL || perfil === TipoPerfilEnum.GESTOR_ORGAO || perfil === TipoPerfilEnum.GESTOR_UNIDADE;
  }

  getMenuTipificacao(perfil: string): Menu {
    const tipificacao: Menu = {
      titulo: 'Tipificação',
      icone: 'fa fa-check-circle',
      opcoes: []
    };
    if (perfil === TipoPerfilEnum.GESTOR_GERAL) {
      tipificacao.opcoes.push(...[{
        titulo: 'Órgãos',
        link: '/tipificacao/orgao'
      }, {
        titulo: 'Unidades',
        link: '/tipificacao/unidade'
      }, {
        titulo: 'Categorias de Serviços',
        link: '/tipificacao/categoria-servico'
      }, {
        titulo: 'Entregas',
        link: '/tipificacao/entrega'
      }, {
        titulo: 'Cadeia de Valores',
        link: '/tipificacao/cadeia-valor'
      }, {
        titulo: 'Indic. de Produtividade',
        link: '/tipificacao/indicador-produtividade'
      }, {
        titulo: 'Indic. de Pessoal',
        link: '/tipificacao/indicador-pessoal'
      }, {
        titulo: 'Objetivo Estratégico',
        link: '/tipificacao/objetivo-estrategico'
      }]);
    } else if (perfil === TipoPerfilEnum.GESTOR_ORGAO) {
      tipificacao.opcoes.push(...[{
        titulo: 'Unidades',
        link: '/tipificacao/unidade'
      }, {
        titulo: 'Entregas',
        link: '/tipificacao/entrega'
      }, {
        titulo: 'Indic. de Produtividade',
        link: '/tipificacao/indicador-produtividade'
      } ]);
    } else {
      tipificacao.opcoes.push({
        titulo: 'Entregas',
        link: '/tipificacao/entrega'
      });
    }
    return tipificacao;
  }

  acessaMenuPessoa(perfil: string): boolean {
    return perfil === TipoPerfilEnum.GESTOR_GERAL || perfil === TipoPerfilEnum.GESTOR_ORGAO || perfil === TipoPerfilEnum.GESTOR_UNIDADE;
  }

  getMenuPessoa(): Menu {
    const pessoal: Menu = {
      titulo: 'Pessoa/Perfil',
      icone: 'fa fa-address-card',
      opcoes: [{
        titulo: 'Pessoas',
        link: '/pessoa-perfil'
      }]
    };
    return pessoal;
  }

  getMenuDimensionamentos(perfil: string): Menu {
    const dimensionamentos: Menu = {
      titulo: perfil !== TipoPerfilEnum.GESTOR_ESTRATEGICO ? 'DFT' : 'Resultados',
      icone: 'fa fa-clipboard',
      opcoes: []
    };

    if (perfil === TipoPerfilEnum.GESTOR_GERAL ||
      perfil === TipoPerfilEnum.GESTOR_ESTRATEGICO ||
      perfil === TipoPerfilEnum.GESTOR_ORGAO ||
      perfil === TipoPerfilEnum.GESTOR_UNIDADE) {
      dimensionamentos.opcoes.push({
        titulo: 'Dimensionamentos',
        link: '/dimensionamento/principal'
      });
      if (perfil !== TipoPerfilEnum.GESTOR_UNIDADE) {
        dimensionamentos.opcoes.push({
          titulo: 'Consolidações',
          link: '/dimensionamento/consolidacao'
        });
      }
    } else if (perfil === TipoPerfilEnum.USUARIO) {
      dimensionamentos.opcoes.push({
        titulo: 'Quantificar Esforços',
        link: '/dimensionamento/quantificacao-esforcos'
      });
    }

    return dimensionamentos;
  }

  getMenuPlanoTrabalhos(perfil: string): Menu {
    const planos: Menu = {
      titulo: perfil !== TipoPerfilEnum.GESTOR_ESTRATEGICO ? 'PGD' : 'Resultados',
      icone: 'fa fa-tasks',
      opcoes: []
    };

    if (perfil === TipoPerfilEnum.GESTOR_GERAL ||
      perfil === TipoPerfilEnum.GESTOR_ESTRATEGICO ||
      perfil === TipoPerfilEnum.GESTOR_ORGAO ||
      perfil === TipoPerfilEnum.GESTOR_UNIDADE) {
        planos.opcoes.push({
          titulo: 'Plano de Entrega da Unidade',
          link: '/plano-entrega'
        });
    }

    planos.opcoes.push({
      titulo: 'Plano de Trabalho',
      link: '/plano-trabalho'
    });

    planos.opcoes.push(
      {
        titulo: 'Avisos',
        link: '/sentinela-avisos'
      }
    );

    return planos;
  }

  private acessaMenuRelatorios(perfil: string): boolean {
    return perfil === TipoPerfilEnum.GESTOR_GERAL || perfil === TipoPerfilEnum.GESTOR_ORGAO;
  }

  getMenuRelatorios(perfil: string): Menu {
    const relatorios: Menu = {
      titulo: 'Relatórios',
      icone: 'fa fa-dashboard',
      opcoes: [{
        titulo: 'Relatório Gerencial',
        link: '/relatorios/relatorio-gerencial'
      }]
    };
    if (this.acessaMenuRelatorios(perfil)) {
      relatorios.opcoes.push(...[{
        titulo: 'Perfil por usuário',
        link: '/relatorios/relatorio-perfil-usuario'
      }, {
        titulo: 'Categorias de Serviço',
        link: '/relatorios/relatorio-categoria-servico'
      }, {
        titulo: 'Descrição das unidades',
        link: '/relatorios/relatorio-descricao-unidade'
      }]);
    }
    return relatorios;
  }

  getMenuCentroCusto(): Menu {
    return {
      titulo: 'Centro de custos',
      icone: 'fa fa-usd',
      opcoes: [{
        titulo: 'Centro de custos',
        link: '/dimensionamento/centro-custo'
      }]
    };
  }

  acessaMenuConfiguracoes(perfil: string): boolean {
    return perfil === TipoPerfilEnum.GESTOR_GERAL;
  }

  getMenuConfiguracoes(): Menu {
    return {
      titulo: 'Configuração',
      icone: 'fa fa-wrench',
      opcoes: [{
        titulo: 'Parâmetros',
        link: '/configuracao/parametro'
      },
      {
        titulo: 'Feriados',
        link: '/configuracao/feriados'
      },
      {
        titulo: 'Parametrização Período PGD',
        link: '/plano-param-periodo'
      },
      {
        titulo: 'Acompanhamento dos envios ao PGD',
        link: '/configuracao/dados-envio-pgd'
      },
      {
        titulo: 'Importação de dados das pessoas',
        link: '/configuracao/importacao-dados-pessoas'
      }
    ]
    };
  }

}
